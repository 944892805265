import React from "react"
import ArticleCard from '../ArticleCard/ArticleCard'


function RelatedArticles(props) {
  const { data } = props
  
  return (
    <>
      {data.edges.map(({ node }, i) => (
        <ArticleCard
          frontMatter={node.frontmatter}
          fields={node.fields}
          key={i}
          hideThumb
        />
      ))}
    </>
  )
}

export default RelatedArticles