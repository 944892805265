import React from "react";
import "./PostMeta.scss";

const _ = require("lodash");

export default function PostMeta(props) {
  const { post: { date, category, role, client, tech, skills, software, projectURL } } = props

  // items that don't need links
  const metaGroups = [
    {display: 'Date', name: date },
    {display: 'Category', name: category },
    {display: 'Role', name: role },
    {display: 'Client', name: client },
    {display: 'Project URL', name: projectURL },
    {display: 'Software', name: software },
  ]

  // items that link to archive pages
  const metaLinks = [
    {display: 'Tech', name: tech },
    {display: 'Skills', name: skills },
  ]

  // return non-link items
  function MetaItem({display, name}) {    
    function MetaContent() {
      if (display === 'Project URL') {
        const pref = name.substring( 0, name.indexOf('//') +2 );
        const domain = name.substring( name.indexOf('//') + 2 );
        return ( 
          <a
            href={`${pref}${domain}`}
            className="postMeta__value"
            style={{overflow: 'hidden', display: 'block'}}
          >
            {domain}
          </a>
        )
      }
      if (typeof name !== 'object') {
        return ( <span className="postMeta__value">{name}</span> )
      }
      return (
        <ul className="postMeta__value">
          {name.map( (item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      )
    }
    return (
      <div className="postMeta__div">
        <strong className="postMeta__label">{display}</strong>
        <MetaContent />
      </div>
    )
  }
  
  // return items that link to archive pages
  function MetaLinkItem({display, name}) {    
    function MetaContent() {
      if (typeof name !== 'object') {
        return ( <span className="postMeta__value">{name}</span> )
      }
      return (
        <ul className="postMeta__value">
          {name.map( (item, i) => (
            <li key={i}>
              <a 
                href={`/${display.toLowerCase()}/${_.kebabCase(item)}/`}
                style={{color: 'var(--color--text--inverse)'}}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      )
    }
    return (
      <div className="postMeta__div">
        <strong className="postMeta__label">{display}</strong>
        <MetaContent />
      </div>
    )
  }

  // return an outer div and inner items 
  return (
    <div className="post_meta inverse" style={{ backgroundColor: "var(--color--bg)" }}>
      {/* non linked meta */}
      {metaGroups.map( (item, i) => {
        if (!item.name) return ''
        return (
          <MetaItem key={i} display={item.display} name={item.name} />
        )
      })}
      {/* linked meta */}
      {metaLinks.map( (item, i) => {
        if (!item.name) return ''
        return (
          <MetaLinkItem key={i} display={item.display} name={item.name} />
        )
      })}
    </div>
  )
}