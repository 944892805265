import { CommentCount } from 'disqus-react';
import BackgroundImage from 'gatsby-background-image';
import React from "react";
import urljoin from "url-join";
import config from "../../../data/SiteConfig";
import IconInline from "../IconInline/IconInline";
import PostTags from '../PostTags/PostTags';
import "./PostBanner.scss";


function PostBanner(props) {
  const { bg, title, subtitle, tags, slug, hideDisqus } = props
  const posturl = urljoin(
    config.siteUrl,
    config.pathPrefix,
    slug
  );
  const style = {
    backgroundSize: "cover",
    backgroundPosition: "center top",
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: "0 0 200px var(--item--banner-glow)",
  }
  const headingStyle = {
    lineHeight: "1.55",
    backgroundColor: "var(--color--bg)",
    padding: "3px 0",
  }
  const tagStyle = {
    color: "currentColor",
    fontSize: "var(--scale--1)",
    background: "var(--color--bg)",
    width: "min-content",
    height: "min-content", 
    textAlign: "right",
    placeSelf: "end",
    gridRow: "1 / 1",
    padding: "3px 0",
    textDecoration: "none",
    whiteSpace: "no-wrap"
  }

  const subStyle = bg ? {color: "#FFFFFF"} : {color: "var(--color--text--min-contrast)"}
  
  const backgroundFluidImageStack = [
    bg,
    `linear-gradient(20deg, rgba(25,25,25,0.8) 20%, rgba(25,25,25,0.4) 80% )`
  ].reverse()

  const DisqusCount = () => {
    return(
      <div style={subStyle}>
        <small
          style={{
            display: 'block',
            marginTop: '0.8rem',
            marginBottom: '0.8rem',
          }}
        >
          
          {
            (!hideDisqus) ? (
              <>
                <IconInline 
                  name='comment'
                  style={{
                    width: '1em',
                    marginRight: '0.8ch',
                  }}
                />
                <CommentCount
                  shortname={config.disqusShortname}
                  config={{
                    url: posturl,
                    identifier: title,
                    title,
                  }}
                >
                  Comments
                </CommentCount>
              </>
            ) : ''
          }
        </small>
      </div>
    )
  }

  const BannerInner = () => {
    return (
      <div className="post__heading">
        <PostTags tags={tags} style={tagStyle} />
        <h1 id="skip__target" style={{ marginTop: "var(--spacing--sub3)" }}>
          <span style={headingStyle}>{title}</span>
        </h1>
        <span style={subStyle}>{subtitle}</span>
        <DisqusCount />
      </div>
    );
  }

  if (bg) {
    return (
      <BackgroundImage
        Tag="div"
        className='container grid grid--outer page__banner inverse'
        fluid={backgroundFluidImageStack}
        style={style}
      >
        <BannerInner />
      </BackgroundImage>
    )
  } 
  return (
    <div className="post__heading inverse">
      <BannerInner />
    </div>
  )
}

export default PostBanner
