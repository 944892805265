import { DiscussionEmbed } from 'disqus-react'
import { graphql } from "gatsby"
import React, { Component } from "react"
import Helmet from "react-helmet"
import config from "../../data/SiteConfig"
import Footer from "../components/Footer/Footer"
import PostBanner from "../components/PostBanner/PostBanner"
import PostHeader from "../components/PostHeader/PostHeader"
import PostMeta from "../components/PostMeta/PostMeta"
import RelatedArticles from "../components/RelatedArticles/RelatedArticles"
import SEO from "../components/SEO/SEO"
// import SocialLinks from "../components/SocialLinks/SocialLinks"
import Layout from "../components/Layout/Layout"
import "../scss/core/b16-tomorrow-dark.scss"
import "./post.scss"

export default class PostTemplate extends Component {
  render() {
    const { data , pageContext } = this.props;
    const { thispost, notFeatured } = data
    const { slug } = pageContext;
    const postNode = thispost;
    const post = postNode.frontmatter;
    
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }
    const coverImage = post.banner ? post.banner.childImageSharp.fluid : null;

    const disqusConfig = {
      shortname: config.disqusShortname,
      config: { identifier: post.title, title: post.title },
    }

    const summaryStyle = {
      color: "var(--color--text--min-contrast)",
      marginTop: "0",
      fontSize: "var(--scale--0)"
    }    

    return (
      <Layout>
        <Helmet><title>{`${post.title} | ${config.siteTitle}`}</title></Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <PostHeader config={config} />

        <main className={`container grid grid--outer main--post ${coverImage ? '' : 'nobanner'}`}>
          

          <article
            className="page__content"
            style={{
              borderBottom: 'var(--border-width--0) solid currentColor',
              marginBottom: 'var(--spacing--1)',
            }}
          >
            <PostBanner bg={coverImage} title={post.title} tags={post.tags} subtitle={post.subTitle} slug={slug} hideDisqus={post.hideDisqus} />

            {
              post.summary ?
                <blockquote>
                  <span className="h2" style={summaryStyle}>Summary:</span>{` ${post.summary}`}
                </blockquote> :
                ''
            }
            <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
          </article>
          
          <aside className="sidebar--right">
            <PostMeta post={post} />
            <div style={{ marginTop: "var(--spacing--0)" }}>
              <RelatedArticles data={notFeatured} />
            </div>
          </aside>

          {/* <aside aria-label="share options" className="sidebar--shares">
            <SocialLinks postPath={slug} postNode={postNode} />
          </aside> */}

          {
            (!post.hideDisqus) ?
              (
                <footer id="disqusWrapper" className="disqusWrapper">
                  <DiscussionEmbed { ...disqusConfig } />
                </footer>
              )
            : ''
          }
        </main>

        <Footer config={config} />

      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    thispost: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subTitle
        summary
        thumb {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED, 
              width: 500, 
              quality: 90,
              formats: [AUTO, WEBP]
            )
          }
        }
        date (formatString: "MMMM Do, YYYY")
        category
        tags
        role
        client
        tech
        software
        skills
        projectURL
        hideDisqus
        banner {
          childImageSharp {
            fluid(
              quality: 90,
              maxWidth: 1920
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
        date
      }
    }
    notFeatured: allMarkdownRemark(
      filter: {
        fields: { slug: { ne: $slug } },
        frontmatter: {
          draft: { ne: true },
          hidden: { ne: true }, 
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          ...IndexPostFragment
        }
      }
    }
  } 
`
